/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-plus': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.5 6a.5.5 0 00-1 0v1.5H6a.5.5 0 000 1h1.5V10a.5.5 0 001 0V8.5H10a.5.5 0 000-1H8.5z"/><path pid="1" d="M2 2a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2zm10-1H4a1 1 0 00-1 1v12a1 1 0 001 1h8a1 1 0 001-1V2a1 1 0 00-1-1"/>',
    },
});
